<template>
  <div class="context">
    <div class="container">
      <div class="box">
        <h1>
          Беспроцентный кредит, который возможен лишь Вам
        </h1>
        <h2>
          Просто укажите в заявке сумму, срок кредита и свои данные для большей вероятности одобрения кредита
        </h2>
      </div>
    </div>
  </div>
  <div class="form container" ref="formDiv">
    <div class="stepper-box">
      <span>
        Шаг {{ stepperRef.modelValue }} из 4
      </span>
    </div>
    <q-stepper class="no-shadow" v-model="step" ref="stepperRef" color="primary" animated>
      <q-step :name="1" title="Шаг 1" :done="step > 1">
        <div class="step-box">
          <div class="step-header">
            <p>Выберите сумму и срок кредита</p>
          </div>
          <div class="step-div">
            <div class="step-value" style="display: none;">
              {{ renameValue(valueCredit) + ' ' + adena }}
            </div>
            <q-input standout="bg-white text-black no-shadow" type="number" :min="minValueCredit" :max="maxValueCredit"
              ref="step1Ref" v-model="valueCredit" label="Желаемая сумма кредита"
              :rules="[(val) => (!isNaN(val) && !!val) && val > minValueCredit - 1 && val < maxValueCredit + 1 || '']" />
            <q-slider v-model="valueCredit" :min="minValueCredit" :max="maxValueCredit" :step="1000" />
            <div class="step-footer">
              <div>
                {{ renameValue(minValueCredit) + ' ' + adena }}
              </div>
              <div>
                {{ renameValue(maxValueCredit) + ' ' + adena }}
              </div>
            </div>
          </div>
          <div class="step-div">
            <q-input standout="bg-white text-black no-shadow" type="number" :min="minValueYear" :max="maxValueYear"
              ref="step11Ref" v-model="valueYear" label="Срок кредита"
              :rules="[(val) => (!isNaN(val) && !!val) && (val > minValueYear - 1 && val < maxValueYear + 1) || '']" />
            <q-slider v-model="valueYear" :min="minValueYear" :max="maxValueYear" :step="1" />
            <div class="step-footer">
              <div>
                <span>{{ minValueYear + ' ' + renameYear(minValueYear) }}</span>
              </div>
              <div>
                {{ maxValueYear + ' ' + renameYear(maxValueYear) }}
              </div>
            </div>
          </div>
          <div class="step-box-footer">
            <span>Вы выбрали: сумму <span class="no-wrap">{{ renameValue(valueCredit) + ' ' + adena }}</span> сроком на
              <span class="no-wrap">{{
                valueYear +
                ' ' + renameYear(valueYear) }}</span></span>
          </div>
        </div>
        <div class="step-box">
          <div class="step-div">
            <q-select standout="bg-white text-black no-shadow" v-model="modelCredit" :options="optionsCredit"
              label="Цель кредита*" ref="step12Ref"
              :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
          </div>
        </div>
        <div class="step-box">
          <div class="step-header">
            <p style="margin-top: 10px;">Контактная информация</p>
          </div>
          <div class="step-div">
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="fio" label="Фамилия, имя и отчество*"
                placeholder="Иванов Алексей Петрович" ref="step13Ref"
                :rules="[(val) => val != null && val.length > 6 || 'Укажите фамилию, имя и отчество через пробел']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="phoneNumber" label="Мобильный телефон*"
                mask="+7 (###) ### - ####" placeholder="+7 (921) 123 - 4567" ref="step14Ref"
                :rules="[(val) => val != null && val.length > 18 || 'Необходимо указать номер телефона']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="eMail" label="Электронная почта"
                placeholder="ivan@domain.ru" />
            </p>
          </div>
        </div>
      </q-step>

      <q-step :name="2" title="Шаг 2" :done="step > 2">
        <div class="step-box">
          <div class="step-header">
            <p>Адрес фактического проживания</p>
          </div>
          <div class="step-div">
            <p>
              <q-checkbox v-model="adressDublicateReg" label="Совподает с адресом регистрации" />
            </p>
            <p>
              <q-input ref="step2Ref" standout="bg-white text-black no-shadow" v-model="region" label="Регион*"
                placeholder="Респ Бурятия"
                :rules="[(val) => val != null && val.length > 3 || 'Введите название региона']" />
            </p>
            <p>
              <q-input ref="step21Ref" standout="bg-white text-black no-shadow" v-model="regionOrCity"
                label="Район или город*" placeholder="г. Чита"
                :rules="[(val) => val != null && val.length > 3 || 'Введите название района']" />
            </p>
            <p>
              <q-input ref="step22Ref" standout="bg-white text-black no-shadow" v-model="city" label="Населённый пункт*"
                placeholder="г. Чита" :rules="[(val) => val != null && val.length > 3 || 'Введите название н.п.']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="street" label="Улица"
                placeholder="мкр Березка" />
            </p>
            <p>
              <q-input ref="step23Ref" standout="bg-white text-black no-shadow" v-model="house" label="Дом*"
                placeholder="21" :rules="[(val) => val != null && val.length > 0 || 'Введите номер дома']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="houseSelection" label="Корпус" placeholder="1" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="stroenie" label="Строение" placeholder="1" />
            </p>
            <p class="m-0">
              <q-input standout="bg-white text-black no-shadow" v-model="houseKv" label="Квартира" placeholder="43" />
            </p>
          </div>
        </div>
        <div class="step-box">
          <div class="step-header">
            <p>Дополнительный телефон</p>
            <p class="step-header-foo">Введите номер, отличный от мобильного: <span class="no-wrap">{{ phoneNumber
            }}</span></p>
          </div>
          <p>
            <q-input standout="bg-white text-black no-shadow" v-model="dopPhoneNumber" label="Дополнительный телефон*"
              mask="+7 (###) ### - ####" placeholder="+7 (921) 123 - 4567" />
          </p>
          <p class="m-0">
            <q-select standout="bg-white text-black no-shadow" v-model="dopPhoneNumberWho"
              :options="optionsDopPhoneNumberWho" label="Владелец телефона*" />
          </p>
        </div>
      </q-step>

      <q-step :name="3" title="Шаг 3" :done="step > 3">
        <div class="step-box">
          <div class="step-header">
            <p>Заполните место работы</p>
          </div>
          <div class="step-div">
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelWork" :options="optionsWork"
                label="Тип занятости*" ref="step3Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="nameWork"
                label="Название организации* (или в/ч если в/служащий)" placeholder="Пенсионный фонд" ref="step31Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="phoneWork" label="Рабочий телефон*"
                mask="+7 (###) ### - ####" placeholder="+7 (921) 123 - 4567" ref="step32Ref"
                :rules="[(val) => val != null && val.length > 18 || 'Необходимо указать номер телефона']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="namePositionWork" label="Название должности*"
                placeholder="Начальник отдела" ref="step33Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelTypeWork" :options="optionsTypeWork"
                label="Тип должности*" ref="step34Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelLvlWork" :options="optionsLvlWork"
                label="Cтаж работы в организации*" ref="step35Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
          </div>
        </div>
        <div class="step-box">
          <div class="step-header">
            <p>Заполните место работы</p>
          </div>
          <div class="step-div">
            <p>
              <q-input ref="step36Ref" standout="bg-white text-black no-shadow" v-model="regionWork" label="Регион*"
                placeholder="Респ Бурятия"
                :rules="[(val) => val != null && val.length > 3 || 'Введите название региона']" />
            </p>
            <p>
              <q-input ref="step37Ref" standout="bg-white text-black no-shadow" v-model="regionOrCityWork"
                label="Район или город*" placeholder="г. Чита"
                :rules="[(val) => val != null && val.length > 3 || 'Введите название района']" />
            </p>
            <p>
              <q-input ref="step38Ref" standout="bg-white text-black no-shadow" v-model="cityWork"
                label="Населённый пункт*" placeholder="г. Чита"
                :rules="[(val) => val != null && val.length > 3 || 'Введите название н.п.']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="streetWork" label="Улица"
                placeholder="мкр Березка" />
            </p>
            <p>
              <q-input ref="step39Ref" standout="bg-white text-black no-shadow" v-model="houseWork" label="Дом*"
                placeholder="21" :rules="[(val) => val != null && val.length > 0 || 'Введите номер дома']" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="houseSelectionWork" label="Корпус"
                placeholder="1" />
            </p>
            <p>
              <q-input standout="bg-white text-black no-shadow" v-model="stroenieWork" label="Строение" placeholder="1" />
            </p>
            <p class="m-0">
              <q-input standout="bg-white text-black no-shadow" v-model="houseOfficeWork" label="Офис" placeholder="43" />
            </p>
          </div>
        </div>
      </q-step>

      <q-step :name="4" title="Шаг 4" :done="step > 4">
        <div class="step-box">
          <div class="step-header">
            <p>Дополнительные сведения</p>
          </div>
          <div class="step-div">
            <p>
              <q-input standout="bg-white text-black no-shadow" type="number" ref="step4Ref" v-model="valueIncome"
                label="Персональный доход в месяц* (₽)"
                :rules="[(val) => (!isNaN(val) && !!val) && val > 1000 || 'Обязательное поле']" />
            </p>
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelEducation" :options="optionsEducation"
                label="Образование" />
            </p>
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelFamily" :options="optionsFamily"
                label="Семейное положение*" ref="step41Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <div v-if="modelFamily == optionsFamily[0] || modelFamily == optionsFamily[2]">
              <p>
                <q-input standout="bg-white text-black no-shadow" type="number" v-model="valueIncomeFamily"
                  label="Средний доход жены/мужа в месяц (₽)" />
              </p>
              <p>
                <q-input standout="bg-white text-black no-shadow" v-model="modelFamilyWork" label="Занятость жены/мужа" />
              </p>
            </div>
            <p>
              <q-checkbox v-model="modelChildren" label="Наличие детей" />
            </p>
            <div v-if="modelChildren">
              <p>
                <q-select standout="bg-white text-black no-shadow" v-model="howMuchChild" :options="optionsHuwMuchChild"
                  label="Количество детей" />
              </p>
              <p v-if="howMuchChild > 0">
                <q-input standout="bg-white text-black no-shadow" v-model="dateChildren1" label="Год рождения ребёнка"
                  mask="####" placeholder="2020" />
              </p>
              <p v-if="howMuchChild > 1">
                <q-input standout="bg-white text-black no-shadow" v-model="dateChildren2" label="Год рождения ребёнка"
                  mask="####" placeholder="2020" />
              </p>
              <p v-if="howMuchChild > 2">
                <q-input standout="bg-white text-black no-shadow" v-model="dateChildren3" label="Год рождения ребёнка"
                  mask="####" placeholder="2020" />
              </p>
              <p v-if="howMuchChild > 3">
                <q-input standout="bg-white text-black no-shadow" v-model="dateChildren4" label="Год рождения ребёнка"
                  mask="####" placeholder="2020" />
              </p>
              <p v-if="howMuchChild > 4">
                <q-input standout="bg-white text-black no-shadow" v-model="dateChildren5" label="Год рождения ребёнка"
                  mask="####" placeholder="2020" />
              </p>
            </div>
          </div>
        </div>
        <div class="step-box">
          <div class="step-header">
            <p>Автомобиль</p>
          </div>
          <div class="step-div">
            <p>
              <q-select standout="bg-white text-black no-shadow" v-model="modelCars" :options="optionsCars"
                label="Тип автомобиля*" ref="step42Ref"
                :rules="[(val) => val != null && val.length > 1 || 'Обязательное поле']" />
            </p>
            <div v-if="modelCars != null && modelCars != optionsCars[0]">
              <p>
                <q-input standout="bg-white text-black no-shadow" v-model="dateCars" label="Год выпуска автомобиля"
                  mask="####" placeholder="2020" />
              </p>
            </div>
            <p class="text-wrap">
              <q-checkbox v-model="debtCredit"
                label="Наличие кредитных задолженностей в других банковских учреждениях, сообществах, иное" />
            </p>
            <div v-if="debtCredit">
              <p>
                <q-input standout="bg-white text-black no-shadow" type="number" v-model="valueDebtCredit"
                  label="Средняя сумма долговых обязательств (₽)" />
              </p>
            </div>
          </div>
        </div>
      </q-step>

      <template v-slot:navigation>
        <div class="navigate">
          <q-stepper-navigation>
            <div class="info" v-if="step == 1">
              <span>Заполняя форму, я принимаю условия передачи информации</span>
            </div>
            <q-btn unelevated no-caps text-color="black" v-if="step > 1" flat color="primary"
              @click="onBackStep(), scrollToTop()" label="Назад" class="q-ml-sm"></q-btn>

            <q-btn unelevated no-caps @click="onContinueStep(), scrollToTop()" color="primary" text-color="black"
              :label="step === 4 ? 'Отправить' : 'Далее'"></q-btn>
          </q-stepper-navigation>
        </div>
      </template>
    </q-stepper>

  </div>
</template>
  
<script>
import { ref } from 'vue'
import axios from 'axios'
import { useQuasar } from 'quasar'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const $q = useQuasar()
    const router = useRouter()

    const stepperRef = ref(1)
    const step1Ref = ref(null)
    const step11Ref = ref(null)
    const step12Ref = ref(null)
    const step13Ref = ref(null)
    const step14Ref = ref(null)
    const step2Ref = ref(null)
    const step21Ref = ref(null)
    const step22Ref = ref(null)
    const step23Ref = ref(null)
    const step3Ref = ref(null)
    const step31Ref = ref(null)
    const step32Ref = ref(null)
    const step33Ref = ref(null)
    const step34Ref = ref(null)
    const step35Ref = ref(null)
    const step36Ref = ref(null)
    const step37Ref = ref(null)
    const step38Ref = ref(null)
    const step39Ref = ref(null)
    const step4Ref = ref(null)
    const step41Ref = ref(null)
    const step42Ref = ref(null)
    const step = ref(1)
    const step1 = ref("")
    const step2 = ref("")
    const step3 = ref("")
    const step4 = ref("")
    const minValueCredit = ref(30000)
    const maxValueCredit = ref(3000000)
    const valueCredit = ref(30000)
    const adena = ref('₽')
    const year = ref(['месяц', 'месяца', 'месяцев'])
    const minValueYear = ref(3)
    const maxValueYear = ref(36)
    const valueYear = ref(3)
    const phoneNumber = ref(null)
    const eMail = ref(null)
    const adressDublicateReg = ref(false)
    const region = ref(null)
    const regionOrCity = ref(null)
    const city = ref(null)
    const street = ref(null)
    const house = ref(null)
    const houseSelection = ref(null)
    const stroenie = ref(null)
    const houseKv = ref(null)
    const dopPhoneNumber = ref(null)
    const dopPhoneNumberWho = ref(null)
    const optionsDopPhoneNumberWho = [
      'Мой номер', 'Рабочий номер', 'Номер жены/мужа', 'Номер родителей', 'Номер родственника'
    ]
    const modelCredit = ref(null)
    const optionsCredit = [
      'Покупка автомобиля', 'Погашение кредитов', 'Погашение ипотеки', 'Лечение', 'Погашение долгов (не кредитов)', 'Путешествие', 'Другое'
    ]
    const fio = ref(null)
    const optionsWork = ['Госслужащий', 'Военнослужащий']
    const modelWork = ref(null)
    const nameWork = ref(null)
    const phoneWork = ref(null)
    const namePositionWork = ref(null)
    const optionsTypeWork = ['Руководитель', 'Начальник отдела', 'Командир подразделения', 'Управление персоналом', 'Начальник службы', 'Обслуживание персонала', 'Технический исполнитель']
    const modelTypeWork = ref(null)
    const optionsLvlWork = ['6 месяцев и менее', '1 год и более', '2 года и более', '3 года и более', '5 лет и более', '10 лет и более', '15 лет и более']
    const modelLvlWork = ref(null)
    const regionWork = ref(null)
    const regionOrCityWork = ref(null)
    const cityWork = ref(null)
    const houseWork = ref(null)
    const streetWork = ref(null)
    const houseSelectionWork = ref(null)
    const stroenieWork = ref(null)
    const houseOfficeWork = ref(null)
    const valueIncome = ref(0)
    const modelEducation = ref(null)
    const optionsEducation = ['Среднее', 'Неполное высшее', 'Высшее', 'Второе высшее']
    const modelFamily = ref(null)
    const optionsFamily = ['Замужем/женат', 'Разведён/разведена', 'Гражданский брак', 'Вдовец/вдова', 'Не замужем/не женат']
    const valueIncomeFamily = ref(0)
    const modelFamilyWork = ref(null)
    const modelChildren = ref(false)
    const dateChildren1 = ref(null)
    const dateChildren2 = ref(null)
    const dateChildren3 = ref(null)
    const dateChildren4 = ref(null)
    const dateChildren5 = ref(null)
    const howMuchChild = ref(1)
    const optionsHuwMuchChild = [1, 2, 3, 4, 5]
    const modelCars = ref(null)
    const optionsCars = ['Нету', 'Отечественный', 'Иномарка']
    const dateCars = ref(null)
    const debtCredit = ref(false)
    const valueDebtCredit = ref(0)

    function onContinueStep() {
      switch (step.value) {
        case 1:
          step1Ref.value.validate()
          step11Ref.value.validate()
          step12Ref.value.validate()
          step13Ref.value.validate()
          step14Ref.value.validate()
          if (!step1Ref.value.hasError && !step11Ref.value.hasError && !step12Ref.value.hasError && !step13Ref.value.hasError && !step14Ref.value.hasError) {
            stepperRef.value.next()
          }
          break;
        case 2:
          step2Ref.value.validate()
          step21Ref.value.validate()
          step22Ref.value.validate()
          step23Ref.value.validate()
          if (!step2Ref.value.hasError && !step21Ref.value.hasError && !step22Ref.value.hasError && !step23Ref.value.hasError) {
            stepperRef.value.next()
          }
          break;
        case 3:
          step3Ref.value.validate()
          step31Ref.value.validate()
          step32Ref.value.validate()
          step33Ref.value.validate()
          step34Ref.value.validate()
          step35Ref.value.validate()
          step36Ref.value.validate()
          step37Ref.value.validate()
          step38Ref.value.validate()
          step39Ref.value.validate()
          if (!step3Ref.value.hasError && !step31Ref.value.hasError && !step32Ref.value.hasError && !step33Ref.value.hasError && !step34Ref.value.hasError && !step35Ref.value.hasError && !step36Ref.value.hasError && !step37Ref.value.hasError && !step38Ref.value.hasError && !step39Ref.value.hasError) {
            stepperRef.value.next()
          }
          break;
        default:
          step4Ref.value.validate()
          step41Ref.value.validate()
          step42Ref.value.validate()
          if (!step4Ref.value.hasError && !step41Ref.value.hasError && !step42Ref.value.hasError) {
            axios.post('https://tbankcredit.ru/api/form', {
              valueCredit: valueCredit.value,
              valueYear: valueYear.value,
              modelCredit: modelCredit.value,
              fio: fio.value,
              phoneNumber: phoneNumber.value,
              eMail: eMail.value,
              adressDublicateReg: adressDublicateReg.value,
              region: region.value,
              regionOrCity: regionOrCity.value,
              city: city.value,
              street: street.value,
              house: house.value,
              houseSelection: houseSelection.value,
              stroenie: stroenie.value,
              houseKv: houseKv.value,
              dopPhoneNumber: dopPhoneNumber.value,
              dopPhoneNumberWho: dopPhoneNumberWho.value,
              modelWork: modelWork.value,
              nameWork: nameWork.value,
              phoneWork: phoneWork.value,
              namePositionWork: namePositionWork.value,
              modelTypeWork: modelTypeWork.value,
              modelLvlWork: modelLvlWork.value,
              regionWork: regionWork.value,
              regionOrCityWork: regionOrCityWork.value,
              cityWork: cityWork.value,
              houseWork: houseWork.value,
              streetWork: streetWork.value,
              houseSelectionWork: houseSelectionWork.value,
              stroenieWork: stroenieWork.value,
              houseOfficeWork: houseOfficeWork.value,
              valueIncome: valueIncome.value,
              modelEducation: modelEducation.value,
              modelFamily: modelFamily.value,
              valueIncomeFamily: valueIncomeFamily.value,
              modelFamilyWork: modelFamilyWork.value,
              modelChildren: modelChildren.value,
              dateChildren1: dateChildren1.value,
              dateChildren2: dateChildren2.value,
              dateChildren3: dateChildren3.value,
              dateChildren4: dateChildren4.value,
              dateChildren5: dateChildren5.value,
              howMuchChild: howMuchChild.value,
              modelCars: modelCars.value,
              dateCars: dateCars.value,
              debtCredit: debtCredit.value,
              valueDebtCredit: valueDebtCredit.value
            })
              .then(function (response) {
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });

            goFinish()
          }
          break;
      }
    }

    function goFinish() {
      router.push('finish')
    }

    function onBackStep() {
      stepperRef.value.previous()
    }

    function resetStep() {
      valueCredit.value = minValueCredit.value
      valueYear.value = minValueYear.value
      phoneNumber.value = null
      eMail.value = null
      adressDublicateReg.value = false
      region.value = null
      regionOrCity.value = null
      city.value = null
      street.value = null
      house.value = null
      houseSelection.value = null
      stroenie.value = null
      houseKv.value = null
      dopPhoneNumber.value = null
      dopPhoneNumberWho.value = null
      modelCredit.value = null
      fio.value = null
      modelWork.value = null
      nameWork.value = null
      phoneWork.value = null
      namePositionWork.value = null
      modelTypeWork.value = null
      modelLvlWork.value = null
      regionWork.value = null
      regionOrCityWork.value = null
      cityWork.value = null
      houseWork.value = null
      streetWork.value = null
      houseSelectionWork.value = null
      stroenieWork.value = null
      houseOfficeWork.value = null
      valueIncome.value = 0
      modelEducation.value = null
      modelFamily.value = null
      valueIncomeFamily.value = 0
      modelFamilyWork.value = null
      modelChildren.value = null
      dateChildren1.value = null
      dateChildren2.value = null
      dateChildren3.value = null
      dateChildren4.value = null
      dateChildren5.value = null
      howMuchChild.value = 1
      modelCars.value = null
      dateCars.value = null
      debtCredit.value = false
      valueDebtCredit.value = 0

      stepperRef.value.goTo(1)
      $q.notify({
        type: 'positive',
        message: 'Ваша заявка отправлена на рассмотрение'
      })
    }

    return {
      step,
      step1,
      step2,
      step3,
      step4,
      stepperRef,
      step1Ref,
      step11Ref,
      step12Ref,
      step13Ref,
      step14Ref,
      step2Ref,
      step21Ref,
      step22Ref,
      step23Ref,
      step3Ref,
      step31Ref,
      step32Ref,
      step33Ref,
      step34Ref,
      step35Ref,
      step36Ref,
      step37Ref,
      step38Ref,
      step39Ref,
      step4Ref,
      step41Ref,
      step42Ref,
      minValueCredit,
      maxValueCredit,
      valueCredit,
      adena,
      year,
      minValueYear,
      maxValueYear,
      valueYear,
      onContinueStep,
      onBackStep,
      goFinish,
      resetStep,
      modelCredit,
      optionsCredit,
      fio,
      phoneNumber,
      eMail,
      adressDublicateReg,
      region,
      regionOrCity,
      city,
      street,
      house,
      houseSelection,
      stroenie,
      houseKv,
      dopPhoneNumber,
      dopPhoneNumberWho,
      optionsDopPhoneNumberWho,
      optionsWork,
      modelWork,
      nameWork,
      phoneWork,
      namePositionWork,
      modelTypeWork,
      optionsTypeWork,
      modelLvlWork,
      optionsLvlWork,
      regionWork,
      regionOrCityWork,
      cityWork,
      houseWork,
      streetWork,
      houseSelectionWork,
      stroenieWork,
      houseOfficeWork,
      valueIncome,
      modelEducation,
      optionsEducation,
      modelFamily,
      optionsFamily,
      valueIncomeFamily,
      modelFamilyWork,
      modelChildren,
      dateChildren1,
      dateChildren2,
      dateChildren3,
      dateChildren4,
      dateChildren5,
      howMuchChild,
      optionsHuwMuchChild,
      modelCars,
      optionsCars,
      dateCars,
      debtCredit,
      valueDebtCredit,
    }
  },
  methods: {
    scrollToTop() {
      this.$refs.formDiv.scrollIntoView({ behavior: 'smooth' });
    },
    renameValue(val) {
      const str = val.toString()

      return str.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
    },
    renameYear(val) {
      let text = ''

      if (val == 1 || val == 21 || val == 31) {
        text = 'месяц'
      } else if (val > 1 && val < 5 || val > 21 && val < 25 || val > 31 && val < 35) {
        text = 'месяца'
      } else {
        text = 'месяцев'
      }

      return text
    },
  },
}
</script>

<style type="scss">
.step-box {
  position: relative;

  .q-slider {
    position: relative;
    bottom: 22px;
  }

  .q-field {
    padding-bottom: 10px;
  }
}

.step-header {
  color: rgba(0, 0, 0, .8);
  font-size: 17px;
  font-weight: 700;
}

.stepper-box {
  color: rgba(0, 0, 0, .4);
  padding: 16px 24px 0;
}

.form .q-stepper__header {
  display: none !important;
}

.step-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 12px;
  font-size: 13px;
  color: rgba(0, 0, 0, .4);
  text-transform: capitalize;
}

/* .step-box .q-field__native {
    color: transparent !important;
} */

.step-div {
  position: relative;
}

.step-value {
  position: absolute;
  top: 26px;
  left: 12px;
  z-index: 12;
}

.step-box-footer {
  color: rgba(0, 0, 0, .8);
  font-size: 15px;
  padding: 10px 0 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.navigate .info {
  font-size: 13px;
  color: rgba(0, 0, 0, .4);
}

.form input {
  color: black !important;
}

.q-field--standout.q-field--highlighted .q-field__control {
  background: rgba(0, 0, 0, .05) !important;
  box-shadow: none !important;
}

.q-field--standout.q-field--highlighted .q-field__native,
.q-field--standout.q-field--highlighted .q-field__prefix,
.q-field--standout.q-field--highlighted .q-field__suffix,
.q-field--standout.q-field--highlighted .q-field__prepend,
.q-field--standout.q-field--highlighted .q-field__append,
.q-field--standout.q-field--highlighted .q-field__input {
  color: black !important;
}

.q-field__bottom.items-start.q-field__bottom--animated {
  padding-top: 2px;
}

.q-item.q-item-type.q-item--active.q-item--clickable {
  background: #ffdd2d;
  color: black;
}

.q-checkbox__svg {
  color: black !important;
}

.step-header-foo {
  color: rgba(0, 0, 0, .6);
  font-size: 14px;
}

.q-stepper__nav {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .info {
    margin-right: 20px;
  }
}

.context .box {
  padding: 0 24px;
}

.navigate {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px;

  .q-stepper__nav {
    padding: 0;
  }
}

.m-0 {
  margin: 0 !important;
}

.q-stepper--horizontal .q-stepper__step-inner {
  padding: 16px !important;
}

.step-header {
  padding-top: 10px;
}

.q-checkbox {
  position: relative;
  left: -10px;
}

.no-wrap {
  white-space: nowrap;
}

.text-wrap .q-checkbox__label {
  white-space: pre-wrap;
}
</style>
