import { createRouter, createWebHistory } from 'vue-router'
import FormView from '../views/FormView.vue'
import FormFinish from '../views/FormFinish.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: FormView,
    meta: {
      title: 'Кредиты онлайн на любые цели специально для Вас | Взять кредит на карту в Тинькофф банке'
    }
  },
  {
    path: '/loans',
    name: 'form',
    component: FormView,
    meta: {
      title: 'Кредиты онлайн на любые цели специально для Вас | Взять кредит на карту в Тинькофф банке'
    }
  },
  {
    path: '/finish',
    name: 'finish',
    component: FormFinish,
    meta: {
      title: 'Кредиты онлайн на любые цели специально для Вас | Взять кредит на карту в Тинькофф банке'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
