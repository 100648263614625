import { createApp } from 'vue'
import { Quasar, Notify, QBtn, QInput, QSlider, QStepper, QSelect, QStepperNavigation, QAvatar, QBadge, QBanner, QBar, QBreadcrumbs, QBreadcrumbsEl, QBtnDropdown, QBtnGroup, QBtnToggle, QCard, QCardActions, QCardSection, QCarousel, QCarouselControl, QCarouselSlide, QChatMessage, QCheckbox, QChip, QCircularProgress, QColor, QDate, QDialog, QDrawer, QEditor, QExpansionItem, QFab, QFabAction, QFile, QFooter, QForm, QHeader, QIcon, QImg, QInfiniteScroll, QInnerLoading, QIntersection, QList, QItem, QItemSection, QItemLabel, QKnob, QLayout, QLinearProgress, QMarkupTable, QMenu, QNoSsr, QOptionGroup, QPage, QPageContainer, QPageScroller, QPagination, QParallax, QPopupEdit, QPopupProxy, QPullToRefresh, QRadio, QRange, QRating, QResizeObserver, QResponsive, QScrollArea, QScrollObserver, QSeparator, QSkeleton, QSlideItem, QSlideTransition, QSpace, QSpinner, QSplitter, QStep, QTabPanels, QTabPanel, QTable, QTd, QTh, QTr, QTabs, QTab, QTime, QTimeline, QTimelineEntry, QToggle, QToolbar, QToolbarTitle, QTooltip, QTree, QUploader, QVideo, QVirtualScroll } from 'quasar'
import '@quasar/extras/material-icons/material-icons.css'
import router from './router'
import store from './store'
import 'quasar/src/css/index.sass'
import './css/style.scss'
import './style.css'
import App from './App.vue'
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'


const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(Quasar, {
        plugins: { Notify },
        components: {
            QBtn, QInput, QSlider, QStepper, QSelect, QStepperNavigation, QAvatar, QBadge, QBanner, QBar, QBreadcrumbs, QBreadcrumbsEl, QBtnDropdown, QBtnGroup, QBtnToggle, QCard, QCardActions, QCardSection, QCarousel, QCarouselControl, QCarouselSlide, QChatMessage, QCheckbox, QChip, QCircularProgress, QColor, QDate, QDialog, QDrawer, QEditor, QExpansionItem, QFab, QFabAction, QFile, QFooter, QForm, QHeader, QIcon, QImg, QInfiniteScroll, QInnerLoading, QIntersection, QList, QItem, QItemSection, QItemLabel, QKnob, QLayout, QLinearProgress, QMarkupTable, QMenu, QNoSsr, QOptionGroup, QPage, QPageContainer, QPageScroller, QPagination, QParallax, QPopupEdit, QPopupProxy, QPullToRefresh, QRadio, QRange, QRating, QResizeObserver, QResponsive, QScrollArea, QScrollObserver, QSeparator, QSkeleton, QSlideItem, QSlideTransition, QSpace, QSpinner, QSplitter, QStep, QTabPanels, QTabPanel, QTable, QTd, QTh, QTr, QTabs, QTab, QTime, QTimeline, QTimelineEntry, QToggle, QToolbar, QToolbarTitle, QTooltip, QTree, QUploader, QVideo, QVirtualScroll
        }
    })

const metaManager = createMetaManager(false, {
    ...defaultConfig,
    ssrAttribute: 'data-meta-ssr',
    tagIDKeyName: 'vmid',
})

app.use(metaManager)
app.mount('#app')
