<template>
  <header class="header">
    <div class="container">
      <RouterLink :to="{ name: 'home' }" class="nav-link">
        <img src="./assets/tinkoff.svg" alt="tinkoff">
      </RouterLink>

      <button class="btn btn-contact">
        <img src="./assets/contact.png" alt="contact">
      </button>
    </div>
  </header>
  <main class="content">
    <RouterView />
  </main>
  <FooterVue />
</template>

<script>
import { RouterView } from "vue-router"
import FooterVue from "./components/Footer.vue";
export default {
  name: 'ContentMain',
  components: {
    RouterView,
    FooterVue
  }
}
</script>

<style lang="scss">
.header {
  background: #f6f7f8;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.btn {
  border: none;
  background: transparent;
  display: block;
  align-items: center;
  justify-content: center;
}

.btn-contact {
  width: 36px;
  height: 36px;
}

.btn img {
  width: 100%;
}

.context {
  background: #f6f7f8;
  padding: 66px 0 10px 0;
}

.context h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: rgba(0, 0, 0, .8);
}

.context h2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  color: rgba(0, 0, 0, .8);
}

.content {
  z-index: 2;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .16), 0 8px 8px rgba(0, 0, 0, .04), inset 0 5px 0 #3e4757;
}

.m-0 {
  margin: 0;
}
</style>
