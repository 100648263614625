<template>
    <div class="footer-line"></div>
    <footer class="footer">
        <div class="box container">
            <div class="footer-logo">
              <svg width="95" height="34" viewBox="0 0 95 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.2971 15.7154C46.6416 15.4632 45.7842 15.3877 44.8512 15.3877H39.6564V13.7738H48.5832V11H36V23.0031H44.5234C45.7086 23.0031 46.5909 22.9023 47.2468 22.6499C48.6337 22.1204 49.5667 20.8344 49.5667 19.1449C49.5667 17.4303 48.6841 16.245 47.2971 15.7154ZM45.4815 19.8762C45.2799 19.9519 45.0025 19.977 44.6493 19.977H39.6564V18.0605H44.6493C45.0529 18.0605 45.3806 18.0859 45.6329 18.2119C45.9354 18.3632 46.0868 18.6406 46.0868 18.9935C46.0868 19.3466 45.9103 19.7249 45.4815 19.8762Z" fill="#333333"/>
                <path d="M59.8189 11H54.473L49.5557 23.0031H53.6913L54.3721 21.2631H59.9197L60.6259 23.0031H64.7363L59.8189 11ZM55.2798 18.6153L57.02 13.7738H57.2973L59.0372 18.6153H55.2798Z" fill="#333333"/>
                <path d="M69.4424 18.5399V23.0032H65.7861V11H69.4424V15.3878H75.3179V11H78.9744V23.0032H75.3179V18.5399H69.4424Z" fill="#333333"/>
                <path d="M84.762 18.4643V23.0032H81.1055V11H84.762V15.5139H85.7706L90.1836 11H94.7728L88.9226 16.7494L94.9999 23.0032H90.133L85.7706 18.4643H84.762Z" fill="#333333"/>
                <path d="M0 4H29V17.5849C29 21.3238 27.0054 24.7789 23.7676 26.6485L14.5001 32L5.23246 26.6485C1.99457 24.7789 3.32377e-06 21.3238 3.32377e-06 17.5849L0 4Z" fill="#FFDD2D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11V15.5077C8.6164 14.8116 9.73715 14.3406 11.0186 14.3406H12.4111V19.5807C12.4111 20.9748 12.0323 22.3399 11.4703 23.0111H17.5276C16.9668 22.3392 16.5889 20.9758 16.5889 19.5834V14.3406H17.9815C19.2629 14.3406 20.3836 14.8116 21 15.5077V11H8Z" fill="#333333"/>
              </svg>
            </div>
            <div class="footer-tel">
                <a href="callto:8 800 755-46-64">8 800 755-46-64</a>
                <p>Для звонков по России</p>
            </div>

        </div>
        <div class="copyright">

        </div>
    </footer>
</template>
  
<script>
export default {
    name: 'FooterVue'
}
</script>

<style lang="scss">
.footer-line {
    background-color: rgb(246, 247, 248);
    height: 56px;
}

.footer {
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 32px;

        .footer-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 32px;

            svg:first-child {
                margin-bottom: 12px;
            }
        }

        .footer-tel {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                text-decoration: none;
                color: rgba(0,0,0,.8);
                letter-spacing: 0;
                font-size: 24px;
                font-weight: 400;
            }

            a:active,
            a:hover {
                outline: 0;
                color: #1f50ba;
            }

            p {
                font-size: 13px;
                color: rgba(0,0,0,.4);
            }
        }
    }

}</style>