<template>
  <div class="finish">
    Ваша заявка отправлена на рассмотрение
  </div>
</template>

<style lang="scss">
.finish {
  margin-top: 60px;
  padding: 100px 0;
  font-size: 24px;
  text-align: center;
}
</style>
